<template>
  <component :is="`logged-${connected ? 'in' : 'out'}`" />
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'FriendsAuth',

    components: {
      LoggedIn: () => import('./LoggedIn'),
      LoggedOut: () => import('./LoggedOut'),
    },

    computed: {
      ...mapState('wallet', ['connected']),
    },
  }
</script>
